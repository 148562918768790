import React from 'react'
import { config, useSpring } from 'react-spring'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { useSiteMetadata } from '../utils'

const Credits = () => (
  <div>
    Design based on <a href="https://github.com/LekoArts/gatsby-starter-portfolio-jodie">work</a> by <a href="https://www.lekoarts.de/en">LekoArts</a>.<br />Much Love ♥
  </div>
);

const AuthorPhoto = styled.figure`
  max-width: 500px;
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.fontSizes[0]};

  > figcaption {
    text-align: right;
  }
`

const About = ({ data }) => {
  const { file: image } = data;

  const site = useSiteMetadata();

  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const creditAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  })

  return (
    <Layout FooterComponent={Credits}>
      <SEO title={'Über mich'} desc={site.description} />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Über mich</h1>
        <AuthorPhoto>
          <Img
            alt="Jana Forkmann"
            key={image.childImageSharp.fluid.src}
            fluid={image.childImageSharp.fluid}
          />
          <figcaption>
            &copy; <a href="https://de-de.facebook.com/Gregor-Laubsch-Photography-305343702856181">Gregor Laubsch Photography</a>
          </figcaption>
        </AuthorPhoto>
        <p>
          Mein Steckenpferd ist die Portrait-Fotographie. Ich bevorzuge die Arbeit mit Tageslicht, verschiedenen Hintergründen und natürlichen Reflektionen. Analog (Polaroid und Mittelformat) und digital.
        </p>
        <p>
          Geboren und aufgewachsen in Berlin.
        </p>
        <h2>Kontakt</h2>
        <p>
          Ich freue mich über Zuschriften an <a href="mailto:post@janaforkmann.de">post@janaforkmann</a>.
        </p>
        <h2>Impressum</h2>
        <p>
          Angaben gemäß § 5 TMG
        </p>
        <p>
          Jana Forkmann<br />
          Tempelhofer Damm 149<br />
          12099 Berlin <br />
        </p>
        <p>
          <strong>Kontakt</strong><br/ >
          E-Mail: <a href="mailto:post@janaforkmann.de">post@janaforkmann</a><br />
        </p>
        <p>
          <strong>Haftung für Links</strong><br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p>
          <strong>Urheberrecht</strong><br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p>
          <strong>Datenschutz</strong><br />
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.

Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export const query = graphql`
query MePhotoQuery {
  file(relativePath: {eq: "me.jpg"}) {
    childImageSharp {
      fluid(quality: 95, maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default About
